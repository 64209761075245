.backdrop-blurred .modal {
    backdrop-filter: blur(7px);
}

div.more-padding {
    .page-block {
        margin: 0 calc(-15px - 1rem);
    }
}

.blur-text{
    filter: blur(5px);
    -webkit-filter: blur(5px);
    // Prevent users from selecting for copy, cut and paste operations
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.blur-toggle-button{
    box-shadow:none !important;
}